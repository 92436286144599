import {
    postServerRequest,
    patchServerRequest,
    getServerRequest
} from '../../../helpers/serverRequest';
import { store } from '../../../store/store';

const httpURL = store.getters.httpURL;

export async function enviarConstancias(idConsejo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/constancias/${idConsejo}/enviarConstancias`;
    return await postServerRequest(url, {}, config);
}

export async function generarConstancias(idConsejo, date) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/constancias/${idConsejo}`;
    return await patchServerRequest(url, { fecha: date }, config);
}
export async function obtenerConstancia(hash) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/certificado/documento/${hash}`;
    return await getServerRequest(url, config);
}

export async function descargarConstancia(hash) {
    const config = {
        headers: {},
        responseType: 'blob',
    };
    const url = `${httpURL}/constancia/descarga//${hash}`;
    return await getServerRequest(url, config);
}
export async function generarPDF(hash, data) {
    const config = {
        responseType: 'blob',
    };
    const url = `${httpURL}/certificado/documento/${hash}`;
    return await postServerRequest(url, { data }, config);
}
export async function generarImage(hash) {
    const config = { headers: {  } };
    const url = `${httpURL}/certificado/datos/${hash}`;
    return await getServerRequest(url, config);
}

export async function obtenerTodasConstancias(usuarioMaster) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/constancia/getConstancias/${usuarioMaster}`;
    return await getServerRequest(url, config);
}